// Generated by Framer (41c59c7)

import { addFonts, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {vFdAaE0nb: {hover: true}};

const cycleOrder = ["vFdAaE0nb"];

const serializationHash = "framer-htKXi"

const variantClassNames = {vFdAaE0nb: "framer-v-10xt0f0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.2, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "vFdAaE0nb", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{webPageId: "FOXBmFxR6"}} openInNewTab={false}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-10xt0f0", className, classNames)} framer-1fkjbqh`} data-framer-name={"Logo"} layoutDependency={layoutDependency} layoutId={"vFdAaE0nb"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"vFdAaE0nb-hover": {opacity: 0.5}}} {...addPropertyOverrides({"vFdAaE0nb-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1sw70m6"} layoutDependency={layoutDependency} layoutId={"eylyUfLLD"}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TWFucm9wZS02MDA=", "--framer-font-family": "\"Manrope\", \"Manrope Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "600", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-485fa98d-eb94-46eb-a183-3a1ab798c476, rgb(9, 9, 9)))"}}>Bethany Robertson</motion.p></React.Fragment>} className={"framer-1jery0a"} fonts={["GF;Manrope-600"]} layoutDependency={layoutDependency} layoutId={"tKN7mMV2I"} style={{"--extracted-r6o4lv": "var(--token-485fa98d-eb94-46eb-a183-3a1ab798c476, rgb(9, 9, 9))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-htKXi.framer-1fkjbqh, .framer-htKXi .framer-1fkjbqh { display: block; }", ".framer-htKXi.framer-10xt0f0 { cursor: pointer; height: 23px; overflow: visible; position: relative; text-decoration: none; width: 173px; }", ".framer-htKXi .framer-1sw70m6 { align-content: flex-start; align-items: flex-start; bottom: 0px; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 0px; justify-content: flex-start; left: 0px; overflow: hidden; padding: 0px 0px 0px 0px; position: absolute; right: 0px; top: 0px; }", ".framer-htKXi .framer-1jery0a { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-htKXi .framer-1sw70m6 { gap: 0px; } .framer-htKXi .framer-1sw70m6 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-htKXi .framer-1sw70m6 > :first-child { margin-top: 0px; } .framer-htKXi .framer-1sw70m6 > :last-child { margin-bottom: 0px; } }", ".framer-htKXi.framer-v-10xt0f0.hover .framer-1sw70m6 { justify-content: flex-end; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 23
 * @framerIntrinsicWidth 173
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"UWSJY59GO":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZad7b3tzW: React.ComponentType<Props> = withCSS(Component, css, "framer-htKXi") as typeof Component;
export default FramerZad7b3tzW;

FramerZad7b3tzW.displayName = "oslo_logo";

FramerZad7b3tzW.defaultProps = {height: 23, width: 173};

addFonts(FramerZad7b3tzW, [{explicitInter: true, fonts: [{family: "Manrope", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE-_A87jxeN7B.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})